import React from "react";
import { toggleState } from "./contact";
import logo from "./../images/showelli-logo.png";

export const Footer = () => (
  <>
    <footer className="footer">
      <div className="container">
        <div className="social-icons"></div>
        {/* <Link to="/#about-us">About Us</Link> /{" "}
            <Link to="/gallery">Gallery</Link> /{" "}
            <Link to="/artists">Artists</Link> / <Link to="/acts">Acts</Link> /{" "} */}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            toggleState();
          }}
        >
          Contact Us
        </a>{" "}
        /{" "}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            toggleState();
          }}
        >
          Book Now
        </a>
      </div>
    </footer>
    <div className="footer-bottom">
      <div className="container">
        <div className="level">
          <div className="level-left">
            {/* <Link to="/" className="navbar-item"> */}
            <img src="/images/showelli-logo.png" alt="Showelli logo" />
            {/* </Link> */}
          </div>
          <div className="level-right">
            <ul>
              <li>
                <a href="mailto:showelli.entertainment@gmail.com">
                  showelli.entertainment@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
);
