import React, { useState } from "react";
// import "./contact.scss";

const emailRegex = RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export let toggleState: () => void;

const encode = (data: { [s: string]: any }): string =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

export const Contact = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submissionResult, setSubmissionResult] = useState<
    string | null | true
  >(null);

  const [validation, setValidation] = useState<{
    name?: string[];
    email?: string[];
    message?: string[];
  }>({});

  const validateName = (name: string): string | void => {
    if (!name || name === "" || name.length <= 1) {
      return "Please enter your name";
    }

    if (name.length >= 30) {
      return "Name is too long";
    }
  };

  const validateEmail = (email: string): string | void => {
    if (!email || email === "" || email.length <= 1) {
      return "Please enter your email";
    }

    if (!emailRegex.test(email)) {
      return "Not a valid email address";
    }
  };

  const validateMessage = (message: string): string | void => {
    if (!message || message === "" || message.length <= 1) {
      return "Please enter your message";
    }
  };

  const validateForm = () => {
    const validation: { [s: string]: string[] } = {};

    if (validateName(name)) {
      validation.name = [validateName(name) as string];
    }

    if (validateEmail(email)) {
      validation.email = [validateEmail(email) as string];
    }

    if (validateMessage(message)) {
      validation.message = [validateMessage(message) as string];
    }

    setValidation(validation);

    return validation;
  };

  toggleState = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = e => {
    setSubmissionResult(null);
    e.preventDefault();

    const validation: { [s: string]: string[] } = validateForm();

    if (Object.keys(validation).length >= 1) {
      setSubmissionResult("Validation errors");
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => {
        setSubmissionResult(true);
      })
      .catch(() => {
        setSubmissionResult(
          "There was an error sending your message, please try again!"
        );
      });
  };

  const renderSubmissionResult = () =>
    submissionResult !== null ? (
      submissionResult === true ? (
        <div className="notification is-success">
          Thank you for your message, {name}! We will reply to you shortly!
        </div>
      ) : (
        <div className="notification is-danger">{submissionResult}</div>
      )
    ) : null;

  return (
    <div className={`modal${isOpen ? ` is-active` : ""}`}>
      <div className="modal-background" onClick={() => toggleState()}></div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => {
          setSubmissionResult(null);
          if (submissionResult === true) {
            setName("");
            setEmail("");
            setMessage("");
            setValidation({});
          }
          toggleState();
        }}
      />
      <div className="modal-content" id="contact">
        <div className="card">
          <div className="card-content">
            <h4 className="title has-underline-block">Contact Us</h4>
            {renderSubmissionResult()}
            <div className="content">
              <p>Use the form below to get in contact with us.</p>
            </div>
            <form onSubmit={handleSubmit} name="contact" method="post">
              <div className="field">
                <div className="control">
                  <label className="label">Your Name</label>
                  <input
                    name="name"
                    className={`input${validation.name ? " is-danger" : ""}`}
                    type="text"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      validateForm();
                    }}
                    placeholder="Your Name"
                  />
                </div>
                {validation.name &&
                  validation.name.map(message => (
                    <p className="help is-danger">{message}</p>
                  ))}
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Your Email Address</label>
                  <input
                    name="email"
                    className={`input${validation.email ? " is-danger" : ""}`}
                    type="text"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      validateForm();
                    }}
                    placeholder="your@email.com"
                  />
                </div>
                {validation.email &&
                  validation.email.map(message => (
                    <p className="help is-danger">{message}</p>
                  ))}
              </div>
              <div className="field">
                <div className="control">
                  <label className="label">Your Message</label>
                  <textarea
                    name="message"
                    className={`textarea${
                      validation.message ? " is-danger" : ""
                    }`}
                    value={message}
                    onChange={e => {
                      setMessage(e.target.value);
                      validateForm();
                    }}
                    placeholder="Your message"
                  />
                </div>
                {validation.message &&
                  validation.message.map(message => (
                    <p className="help is-danger">{message}</p>
                  ))}
              </div>
              <div className="field">
                <div className="control">
                  <input
                    name="message"
                    type="submit"
                    className={`button is-primary${
                      submissionResult === true ? " is-disabled" : ""
                    }`}
                    value="Send"
                    disabled={submissionResult === true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
