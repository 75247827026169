import React from "react";
import "./../index.scss";
import { Contact, Footer, Header } from "../components";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <>
      <Helmet>
        <title>Showelli | Bespoke Entertainment</title>
        <meta name="description" content="Showelli events and TODO" />
        <meta
          name="keywords"
          content="Showelli, dancers, performers, choreography, music videos, concerts, backup dancers, entertainment, fire performers"
        />
      </Helmet>
      <Header />
      <div className="hero is-medium is-black has-background-image">
        <div
          className="background-image"
          style={{
            backgroundImage: "url('/images/eliza-hero.jpeg')",
            backgroundPosition: "0% 40%",
            backgroundSize: "100%",
            opacity: "0.5",
          }}
        >
          {/* <img
            src={hero}
            style={{
              maxWidth: "auto",
              opacity: 0.6,
            }}
          /> */}
        </div>
        <div className="hero-body">
          <h2 className="title has-text-centered is-title-text">SHOWELLI</h2>
          <h1 className="title has-text-centered is-title-text">
            ENTERTAINMENT
          </h1>
          <h3 className="subtitle has-text-centered">Bespoke Performers</h3>
        </div>
      </div>
      <section className="section is-black">
        <div className="container">
          <div className="columns is-vcentered is-mobile is-centered is-wrapped">
            <div className="column is-2">
              <img src="/images/indigo.png" alt="Indigo at the o2" />
            </div>
            <div className="column is-2">
              <img src="/images/modball.png" alt="Modball" />
            </div>
            <div className="column is-2">
              <img src="/images/limahl.png" alt="Limahl" />
            </div>
            <div className="column is-2">
              <img src="/images/UNCOMMON.png" alt="UNCOMMON" />
            </div>
            <div className="column is-2">
              <img src="/images/youmebrunch.png" alt="youmebrunch" />
            </div>
            <div className="column is-2">
              <img src="/images/nightcap.png" alt="Night Cap" />
            </div>
            <div id="playhxuse" className="column is-2">
              <img src="/images/playhxuse.png" alt="play hxuse" />
            </div>
            <div className="column is-2">
              <img src="/images/skinnykitchen.webp" alt="skinny kitchen" />
            </div>
            <div className="column is-2" id="aseye">
              <img src="/images/aseye.png" alt="aseye" />
            </div>
          </div>
        </div>
      </section>
      <Contact />
      <Footer />
    </>
  );
};
