import React from "react";
import { createRoot } from "react-dom/client";
import Homepage from "./homepage";

const root = document.getElementById("root");

createRoot(root).render(
  <React.StrictMode>
    <Homepage />
  </React.StrictMode>
);
